<template>
    <div class="debt-text">
        <div class="card">
            <div class="card-body">
                <p class="text-end mb-3">
                    {{ $t('claim-text.recipient-address') }} получателя: {{ recipientAddress }}<br/>
                    {{ $t('claim-text.sender-username') }}: {{ senderUsername }}<br/>
                    {{ $t('claim-text.sender-address') }} отправителя: {{ senderAddress }}
                </p>
                <h5 class="mb-4 text-center">{{ $t('debt-notice.title').toUpperCase() }}</h5>
                
                <p v-html="text"></p>
                
                <p style="margin: 30px 0 60px;">
                    <b>{{ senderUsername }}</b>
                    <span class="d-block" v-if="representative">{{ representative }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import { generate } from '../../../libs/generator'
    import { shortenCompanyName } from '../../../libs/text'

    export default {
        data: () => ({
            text: ''
        }),
        props: {
            address: {
                type: String,
                default: ''
            },
            senderData: {
                type: Object,
                default: {}
            },
            amount_owed: {
                type: String,
                default: ''
            },
            recipient: {
                type: Object,
                default: {}
            }
        },
        mounted() {
            this.generateText()
        },
        watch: {
            amount_owed: function () {
                this.generateText()
            },
            address: function () {
                this.generateText()
            },
            senderData: function () {
                this.generateText()
            }
        },
        computed: {
            recipientAddress: {
                get() {
                    if(!this.address) return this.$t('claim-text.default-recipient-address')

                    return this.address
                }
            },
            senderUsername: {
                get() {
                    if(!this.senderData?.title) return this.$t('claim-text.default-sender-username')

                    if(this.senderData.company_name) return shortenCompanyName(this.senderData.company_name)               

                    return this.senderData.title
                }
            },
            representative: {
                get() {
                    if(!this.senderData || !this.senderData.company_name) return ''
                    return this.senderData.title
                }
            },
            senderAddress: {
                get() {
                    if(!this.senderData || !this.senderData.address) return this.$t('claim-text.default-sender-address')
                    return this.senderData.address
                }
            },
            totalAmount: {
                get() {
                    if(!this.amount_owed) return '[СУММА ЗАДОЛЖЕННОСТИ]'

                    return this.amount_owed
                }
            }
        },
        methods: {
            generateText() {
                let data = {
                    occurrence_date: this.occurrence_date,
                    amount_owed: this.amount_owed,
                    template_fields: {
                        object_address: {
                            field_value: this.recipientAddress
                        }
                    }
                }

                let text = generate('debt_notice', data, this.senderData, this.recipient)

                this.text = text
            }
        }
    }
</script>

<style scoped>

.debt-text {
    max-width: 800px;
    margin: 0 auto;
    text-align: justify;
}

@media (min-width: 1024px) {
    .card-body {
        padding: 3em 4em;
    }
}

</style>