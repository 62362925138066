<template>
    <div class="modal fade" :id="id" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content p-3 text-bg-light">
                <div class="modal-body">
                    <h5 class="mb-3">{{ $t('claim.decline-reason-title') }}</h5>
                    <div class="form-check" v-for="r in reasons">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" :id="'radioDecline' + r.id" :value="r.title" @change="onChange">
                        <label class="form-check-label" :for="'radioDecline' + r.id">
                            {{ declineReasons[r.title] ?? declineReasons.other }}
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="radioDeclineOther" value="other" @change="onChange">
                        <label class="form-check-label" for="radioDeclineOther">
                            {{ $t('claim.decline-reasons.other') }}
                        </label>
                    </div>
                    <div class="mt-3">
                        <textarea class="form-control" rows="2" ref="textArea" :disabled="!showTextArea" v-model="reasonText"></textarea>
                    </div>
                <!-- </div>
                <div class="modal-footer"> -->
                    <button v-if="!loading" class="btn btn-danger mt-3" @click="decline()" :class="{ loading: loading }" :disabled="reason.length==0||(reason=='other'&&reasonText=='') || loading">{{ $t('claim.btn-decline') }}</button>
                    <button v-else class="btn btn-danger mt-3 loading" disabled></button>
                    <button v-if="!loading" class="btn btn-secondary ms-3" data-bs-dismiss="modal">{{ $t('claims.add.confirm-modal.cancel') }}</button>
                    <button v-else class="btn btn-secondary ms-3 loading" disabled></button>
                </div>
                <button type="button" class="d-none" ref="declineClose" data-bs-dismiss="modal"></button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data: () => ({
        id: 'confirmDeclineModal',
        reasons: [],
        reason: '',
        reasonText: '',
        showTextArea: false,
        loading: false
    }),
    computed: {
        declineReasons: {
            get() {
                return {
                    disagree: this.$t('claim.decline-reasons.disagree'),
                    mistake: this.$t('claim.decline-reasons.mistake'),
                    other: this.$t('claim.decline-reasons.other')
                }
            }
        }
    },
    props: {
        claimId: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.getDeclineReasons()
    },
    methods: {
        async decline() {
            this.loading = true

            if(this.reasonText) this.reason = this.reasonText

            if(this.reason.length >= 1024) {
                this.$notify({
                    title: this.$t('notify.types.error'),
                    text: this.$t('claim.decline-overflow'),
                    type: 'error'
                })

                this.loading = false

                return
            }

            let url = process.env.VUE_APP_API_URL + '/claims/' + this.claimId + '/decline'

            await axios.post(url, {
                text: this.reason
            }, {
                withCredentials: true
            }).then(async function (response) {
                let r = response.data

                if(r.result !== 'SUCCESS') {
                    this.$notify({
                        title: this.$t('notify.types.error'),
                        text: this.$t('claim.decline-error'),
                        type: 'error'
                    })
                }
            }).catch(function (err) {
                if(process.env.VUE_APP_DEBUG) console.log(err)
            })

            this.loading = false
            this.$refs.declineClose.click()
            this.$emit('declined')
        },
        getDeclineReasons() {
            this.reasons = []

            let url = process.env.VUE_APP_API_URL + '/claims/decline-reasons'

            axios.get(url, {
                withCredentials: true
            })
            .then((response) => {
                let r = response.data

                this.reasons = r.data
            })
        },
        onChange(event) {
            if(process.env.VUE_APP_DEBUG) console.log('Changed', event.target.value)

            if(event.target.value == 'other') {
                this.showTextArea = true

                this.$nextTick(() => {
                    this.$refs.textArea.focus()
                })
            } else {
                this.reasonText = ''
                this.showTextArea = false
            }

            this.reason = event.target.value
        }
    }
}

</script>

<style scoped>

.loading {
    padding: 0 2.7rem;
}

.loading::after {
    color: #fff;
}

</style>